import React from 'react';
import { MenuItem, Select } from '@mui/material';

function Language(props: { language: any; handleLanguage: (arg0: string) => void; }) {
    return (
        <Select
            id="languageSelector"
            value={props.language}
            style={{color: 'white', position: 'absolute', top: '10px', right: '10px'}}
            variant="standard"
            sx={{ minWidth: 10, backgroundColor: 'black'}}
            disableUnderline
            MenuProps={{
            MenuListProps: {
                sx: {
                    backgroundColor: 'black'
                }
            }
            }}
        >
            <MenuItem value={'en'} onClick={()=>props.handleLanguage("en")}><span className="fi fi-us"></span></MenuItem>
            <MenuItem value={'fr'} onClick={()=>props.handleLanguage("fr")}><span className="fi fi-fr"></span></MenuItem>
            <MenuItem value={'pt'} onClick={()=>props.handleLanguage("pt")}><span className="fi fi-br"></span></MenuItem>
        </Select>
  );
}

export default Language;