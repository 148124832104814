export const TRANSLATIONS_FR = {
  "Works": "Oeuvres",
  "Insta": "Insta",
  "About": "À propos",
  "Language" : "fr",
                
  "TextAbout1" : "Leonardo Lima est un artiste brésilien de l'état du Paraíba.<br/>"
                + "Avec des traits intuitifs, organiques et abstraits, il recherche un langage<br/>"
                + "affectif et cohérent, axé sur la transmission de l'essence de la nature.<br/>"
                + "<br/>"
                + "&quot;La nature est mon objet principal, auquel nous appartenons en tant<br/>"
                + "qu'êtres vivants, dont nous rapprochons et/ou nous éloignons en<br/>"
                + "tant qu'humains&quot;.",
  "TextAbout2" : "Son style propre, minimaliste, souvent monochromatique et abstrait,<br/>"
                + "valorise les lignes et se concentre sur la pureté des formes pour encourager<br/>"
                + "un regard réflexif et poétique sur la nature, les émotions et relations<br/>"
                + "humaines.<br/>"
                + "<br/>"
                + "Suivez <a class=\"aInstaAbout\" href=\"https://www.instagram.com/leonardo_lima.art\">@leonardo_lima.art</a> sur Instagram.",
  
  "Copyright" : "Copyright © 2023 Leonardo Lima - Tous les droits réservés",

  "All" : "tous",

  "Buy" : "Acheter",
  "SoldOut" : "Épuisé",

  "Size" : "Format",
  "Firstname" : "Prénom",
  "Lastname" : "Nom",
  "Email" : "Email",
  "Phone" : "Téléphone",
  "Address" : "Adresse",
  "City" : "Ville",
  "Zipcode" : "Code postal",
  "Message" : "Message",
  "Send" : "Envoyer",
    
  "Text-serie-faunaflora" : "<b>Série Faunaflora</b><br/>"
                            + "<br/>"
                            + "J'aime observer la charmante simplicité de la nature. Les animaux qui se reposent, à l'abri ou non<br/>"
                            + "de leurs prédateurs, l'instant contraignant à la recherche de l'aliments, la symbiose inconsciente entre<br/>"
                            + "les êtres, la pleine vie dans la nature. L'évidence est réelle et ignorée ! Dans cette série, j'aime<br/>"
                            + "peindre la photographie de souvenirs affectifs avec des approximations de la vie naturelle.<br/>"
                            + "“Nous les humains, où en sommes-nous dans la nature?”",
  "Text-serie-abracos" : "<b>Série Abraços</b><br/>"
                        + "<br/>"
                        + "La série Abraços nous invite à réfléchir sur les émotions et les relations humaines à partir de<br/>"
                        + "stimulus provoqués par une trace organique et des volumes abstraits qui suggèrent les mouvements<br/>"
                        + "du corps. Les peintures de la série Abraços s'adaptent à la perspective de l'observateur<br/>"
                        + "lorsque vous changez de position. “Les choses viennent quand on les cherche...”",
  "Text-serie-olhosVegetais" : "<b>Série Olhos Vegetais</b><br/>"
                              + "<br/>"
                              + "C'est en voyant les plantes, notamment les vignes, que j'ai remarqué les “Olhos Vegetais”. J'aime voir,<br/>"
                              + "dans le vent qui tente de les renverser, les racines adventives dressées et attentives au nouveau<br/>"
                              + "meilleur chemin... s'agrippent avec leurs ventouses en quête de lumière, de vie. En peignant cette série,<br/>"
                              + "je cherche, dans cette quête infatigable, l'idée de penser que nous avons aussi besoin de cette force<br/>"
                              + "d'attachement à la vie, comme amour pur et simple pour quelque chose qui nous est donné chaque jour.<br/>"
                              + "“Soyez léger, tout est semence...”",
  "Text-serie-mangal" : "<b>Série Mangal</b><br/>"
                      + "<br/>"
                      + "",

  "EmailSendSuccess" : "Merci pour la visite. Je vous contacterai sous peu pour finaliser votre commande.",
  "EmailSendError" : "ERREUR! Désolé pour le dérangement.",

  "Shipping" : "Livraison",
  "Price" : "Prix"
};
