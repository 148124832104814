export const TRANSLATIONS_PT = {
  "Works": "Obras",
  "Insta": "Insta",
  "About": "Sobre",
  "Language" : "pt",

  "TextAbout1" : "Leonardo Lima é um artista paraibano, brasileiro.<br/>"
                + "Com traçados intuitivos, orgânicos e abstratos, busca uma linguagem<br/>"
                + "afetiva e consistente, focada em transmitir a essência da natureza.<br/>"
                + "<br/>"
                + "&quot;A natureza é meu principal objeto, a qual pertencemos como seres<br/>"
                + "vivos, da qual nos aproximamos e/ou nos distanciamos como<br/>"
                + "humanos&quot;.",
  "TextAbout2" : "Seu estilo próprio, minimalista, muitas vezes monocromático e abstrato,<br/>"
                + "valoriza os traçados e se concentra na pureza das formas para estimular<br/>"
                + "um olhar reflexivo e poético sobre a natureza e as emoções e relações<br/>"
                + "humanas.<br/>"
                + "<br/>"
                + "Siga <a class=\"aInstaAbout\" href=\"https://www.instagram.com/leonardo_lima.art\">@leonardo_lima.art</a> no instagram.",
  
  "Copyright" : "Copyright © 2023 Leonardo Lima - Todos os direitos reservados",

  "All" : "todas",

  "Buy" : "Comprar",
  "SoldOut" : "Esgotado",

  "Size" : "Formato",
  "Firstname" : "Nome",
  "Lastname" : "Sobrenome",
  "Email" : "Email",
  "Phone" : "Telefone",
  "Address" : "Endereço",
  "City" : "Cidade",
  "Zipcode" : "CEP",
  "Message" : "Mensagem",
  "Send" : "Enviar",

  "Text-serie-faunaflora" : "<b>Série Faunaflora</b><br/>"
                            + "<br/>"
                            + "Gosto de observar a simplicidade encantadora da natureza. Os bichos que descansam,<br/>"
                            + "seguros ou não dos seus predadores, o instante atendo da busca pelo alimento, a<br/>"
                            + "simbiose inconsciente entre seres, a vida plena na natureza. O óbvio é real e ignorado!<br/>"
                            + "Nesta série gosto de pintar a fotografia de memórias afetivas de aproximações com a<br/>"
                            + "vida natural. “Nós humanos, onde estamos na natureza? ”",
  "Text-serie-abracos" : "<b>Série Abraços</b><br/>"
                        + "<br/>"
                        + "A série abraços nos convida a refletir sobre as emoções e relações humanas a partir de estímulos<br/>"
                        + "provocados por um traçado orgânico e volumes abstratos que sugerem movimentos corporais. As<br/>"
                        + "pinturas da série abraços se adaptam a perspectiva do observador a medida em que se altera a posição<br/>"
                        + "das mesmas. “As coisas surgem quando as buscamos...”",
  "Text-serie-olhosVegetais" : "<b>Série Olhos Vegetais</b><br/>"
                              + "<br/>"
                              + "Foi vendo as plantas, sobretudo as trepadeiras, que percebi os “olhos vegetais”. Gosto<br/>"
                              + "de ver, ao vento que as tenta derrubar, as raízes adventícias eretas e atentas ao novo<br/>"
                              + "melhor caminho... se grudam com suas ventosas em busca de luz, de vida. Ao pintar<br/>"
                              + "essa série, busco, nessa busca incansável, a ideia de pensarmos que também<br/>"
                              + "precisamos dessa força do apego à vida, como amor puro e simples por algo que nos é<br/>"
                              + "dado todos os dias. “Seja luz, tudo é semente...”",
  "Text-serie-mangal" : "<b>Série Mangal</b><br/>"
                      + "<br/>"
                      + "",

  "EmailSendSuccess" : "Obrigado pela visita. Em breve farei contato para finalizarmos seu pedido.",
  "EmailSendError" : "ERRO! Desculpe pelo transtorno.",

  "Shipping" : "Frete",
  "Price" : "Preço"
};
