export function openNav() {
    (document.getElementById("myNav") as HTMLInputElement).style.width = "30%";
}
export function closeNav() {
    (document.getElementById("myNav") as HTMLInputElement).style.width = "0%";
}

export function filterSelection(c: string) {
    var filterDiv, i, filterBtn, filterTextDiv;
    filterDiv = document.getElementsByClassName("filterDiv");
    filterBtn = document.getElementsByClassName("filterBtn");
    filterTextDiv = document.getElementsByClassName("filterTextDiv");
    for (i = 0; i < filterBtn.length; i++) {
        removeClass(filterBtn[i], "active");
        if (filterBtn[i].className.indexOf(c) > -1) addClass(filterBtn[i], "active");
    }
    for (i = 0; i < filterTextDiv.length; i++) {
        addClass(filterTextDiv[i], "hidden")
        if (filterTextDiv[i].className.indexOf(c) > -1) removeClass(filterTextDiv[i], "hidden");
    }
    if (c === "all") c = "";
    for (i = 0; i < filterDiv.length; i++) {
        removeClass(filterDiv[i], "hidden");
        if (filterDiv[i].className.indexOf(c) === -1) addClass(filterDiv[i], "hidden");
    }
    closeNav();
}

export function addClass(element: Element, name: string) {
    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
        if (arr1.indexOf(arr2[i]) === -1) {
            element.className += " " + arr2[i];
        }
    }
}

export function removeClass(element: Element, name: string) {
    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
        while (arr1.indexOf(arr2[i]) > -1) {
            arr1.splice(arr1.indexOf(arr2[i]), 1);
        }
    }
    element.className = arr1.join(" ");
}
