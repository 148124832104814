export const TRANSLATIONS_EN = {
  "Works" : "Works",
  "Insta" : "Insta",
  "About" : "About",
  "Language" : "en",

  "TextAbout1" : "Leonardo Lima is a Brazilian artist from the state of Paraíba.<br/>"
                + "With intuitive, organic and abstract strokes, he seeks an affective and<br/>"
                + "consistent language, focused on conveying the essence of nature.<br/>"
                + "<br/>"
                + "&quot;Nature is my main object, which we belong as living beings,<br/>"
                + "which we approach and/or distance ourselves as humans&quot;.",
  "TextAbout2" : "His own style, minimalist, often monochromatic and abstract,<br/>"
                + "put in value lines and focuses on the purity of forms to encourage a<br/>"
                + "reflective and poetic look at nature and the human emotions and<br/>"
                + "relationships.<br/>"
                + "<br/>"
                + "Follow <a class=\"aInstaAbout\" href=\"https://www.instagram.com/leonardo_lima.art\">@leonardo_lima.art</a> on Instagram.",
  
  "Copyright" : "Copyright © 2023 Leonardo Lima - All rights reserved",

  "All" : "all",

  "Buy" : "Buy",
  "SoldOut" : "Sold out",

  "Size" : "Size",
  "Firstname" : "Firstname",
  "Lastname" : "Lastname",
  "Email" : "Email",
  "Phone" : "Phone",
  "Address" : "Address",
  "City" : "City",
  "Zipcode" : "Zipcode",
  "Message" : "Message",
  "Send" : "Send", 
    
  "Text-serie-faunaflora" : "<b>Series Faunaflora</b><br/>"
                            + "<br/>"
                            + "I like to observe the charming simplicity of nature. The animals that rest, safe or not from their<br/>"
                            + "predators, the instant restricted on the search of aliment, the unconscious symbiosis between beings,<br/>"
                            + "the full life in nature. The obvious is real and ignored! In this series I like to paint the photograph of<br/>"
                            + "affective memories with natural life approximations. “We humans, where are we in nature?”",
  "Text-serie-abracos" : "<b>Series Abraços</b><br/>"
                        + "<br/>"
                        + "The Abraços series invites us to reflect on emotions and human relationships based on stimulus<br/>" 
                        + "provoked by an organic trace and abstract volumes that suggest body movements. The<br/>"
                        + "paintings in the Abraços series adapt to the observer's perspective as you change their position.<br/>"
                        + "“Things come when we look for them...”",
  "Text-serie-olhosVegetais" : "<b>Series Olhos Vegetais</b><br/>"
                              + "<br/>"
                              + "It was when I saw the plants, especially the vines, that I noticed the “Olhos Vegetais”. I like<br/>"
                              + "to see, in the wind that tries to knock them down, the adventitious roots erect and attentive to the new<br/>"
                              + "best path... sticking together with their suction cups in search of light, of life. When painting<br/>"
                              + "this series, I seek, in this tireless quest, the idea of thinking that we also need this strength of<br/>"
                              + "attachment to life, as pure and simple love for something that is given to us every day.<br/>"
                              +  "“Be light, everything is seed...”",
  "Text-serie-mangal" : "<b>Series Mangal</b><br/>"
                      + "<br/>"
                      + "",

  "EmailSendSuccess" : "Thanks for the visit. I will contact you shortly to finalize your order.",
  "EmailSendError" : "ERROR! Sorry for the inconvenience.",

  "Shipping" : "Shipping",
  "Price" : "Price"

};
