import React from 'react';
import { useTranslation } from "react-i18next";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { closeNav, filterSelection, openNav } from '../utils/FilterUtil';

function Filter(props: any) {
    const { t } = useTranslation();
    return (
        <div className="filter">
            <div id="filter" style={{fontSize: '30px', cursor: 'pointer', color: 'white', position: 'absolute', top: '180px', right: '30px'}} >
              <FilterAltOutlinedIcon style={{fontSize: '25px'}} onClick={() => openNav()}/>
            </div>
            <div id="myNav" className="overlay">          
                <a href="/#" className="closebtn" onClick={() => closeNav()}>&times;</a>
                <div className="overlay-content">
                    <a className="filterBtn all active" href="/#" onClick={()=>filterSelection("all")}>{t("All")}</a>
                    <a className="filterBtn faunaflora" href="/#" onClick={()=>filterSelection("faunaflora")}>faunaflora</a>
                    <a className="filterBtn abracos" href="/#" onClick={()=>filterSelection("abracos")}>abraços</a>
                    <a className="filterBtn olhosVegetais" href="/#" onClick={()=>filterSelection("olhosVegetais")}>olhos vegetais</a>
                    <a className="filterBtn mangal" href="/#" onClick={()=>filterSelection("mangal")}>mangal</a>
                    <a className="filterBtn semSerie" href="/#" onClick={()=>filterSelection("semSerie")}>sem série</a>
                </div>
            </div>
        </div>
    )
}

export default Filter;