import React from 'react';
import { useTranslation } from "react-i18next";

function About(props: any) {
    const { t } = useTranslation();
    return (
        <div className="parent">
            <div className='rowFull'>
            <div className='column-45'>
                <img src={"./images/leo1.jpg"}  alt='' className="image" style={{width:"100%"}}></img>
            </div>
            <div className='column-55' style={{color: "#FFFFFF", display: "flex", justifyContent: "center" , alignItems: "center"}}>
                <div style={{display: "inline-block",  verticalAlign: "middle"}}>
                    <text dangerouslySetInnerHTML={{ __html: t("TextAbout1")}}></text>
                </div>
            </div>
            <div className='column-55' style={{color: "#FFFFFF", display: "flex", justifyContent: "center" , alignItems: "center"}}>
                <div style={{display: "inline-block",  verticalAlign: "middle"}}>
                    <text dangerouslySetInnerHTML={{ __html: t("TextAbout2")}}></text>
                </div>
            </div>
            <div className='column-45'>
                <img src={"./images/leo2.jpg"}  alt='' className="image" style={{width:"100%"}}></img>
            </div>
            </div>
        </div>
    )
}

export default About;