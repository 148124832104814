import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCyTgtOsmrMmXO__PRXWHI5oQvcdnlyZMI',
  authDomain: 'leonardolima-art.firebaseapp.com',
  projectId: 'leonardolima-art',
  storageBucket: 'leonardolima-art.appspot.com',
  messagingSenderId: '185124985003',
  appId: '1:185124985003:web:12537beec6bd6dbd867fee',
  measurementId: 'G-0YVCXT0KGT',
};

export const defaultUrl =
  'https://firebasestorage.googleapis.com/v0/b/leonardolima-art.appspot.com/o/thumbnails%2F';
export const endUrl = '?alt=media';

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const database = getFirestore(app);
