import React from 'react';
import { Modal, Fade, AlertColor } from '@mui/material';
import { useTranslation } from "react-i18next";
import BuyForm from './BuyForm';
import { defaultUrl, endUrl } from '../services/FirebaseService';

function ModalContainer(props: { openModal: boolean; openBuyForm: boolean; image: string; available: boolean; handleClose: () => void; showBuyForm: (arg0: string) => void;   
    setAlertProperties: (arg0: boolean, arg1: string, arg2: AlertColor) => void; }) {
    const { t } = useTranslation();
    const close = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      props.handleClose()
    }
    const imageDivClassName = props.openBuyForm ? 'hidden' : '';
    const buyFormClassName = props.openBuyForm ? '' : 'hidden';
    let button;
    if (props.available) {
      button = <button className='buyBtn' onClick={() => props.showBuyForm(props.image)}>{t('Buy')}</button>
    } else {
      button = <button className='buyBtnSoldOut' disabled>{t('SoldOut')}</button>
    }
    return (
        <Modal
          open={props.openModal}
          onClose={props.handleClose}
          style={{ display: "inline-flex", justifyContent: "center" , alignItems: "center", background: "rgba(0, 0, 0, 0.8)"}}
        >
            <Fade in={props.openModal} timeout={500} >
              <div>
                <div id="imageDiv" className={imageDivClassName} style={{ display: "inline-flex", justifyContent: "center" , alignItems: "center"}}>
                  <a href="/#" className="closebtnimage" onClick={(e) => close(e)}>&times;</a>
                  <img
                      src={defaultUrl + props.image + endUrl}
                      alt=""
                      style={{position: 'absolute', maxWidth: '80%', width: 'auto', height: 'auto', maxHeight: '80%', outline: "none" }}
                    />
                  {button}
                </div>
                <div id="buyForm" className={buyFormClassName} style={{ display: "flex", justifyContent: "center" , alignItems: "center"}}>
                  <BuyForm image={props.image} handleClose={props.handleClose} setAlertProperties={props.setAlertProperties}></BuyForm>
                </div>
              </div>
            </Fade>
        </Modal>
    );
}

export default ModalContainer;