import { Size } from '../types/Types';

export const getPrice = (size: Size) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const language = window.navigator.language;
    let price = size.priceUs;
    if (timezone.startsWith("Europe")) {
        price = size.priceEu;
    } else if (language === "pt-BR") {
        price = size.priceBr;
    }
    return price;
}