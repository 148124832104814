import React from 'react';
import { useTranslation } from "react-i18next";
import { defaultUrl, endUrl } from '../services/FirebaseService';
import { Row } from '../types/Types';
import { getPrice } from '../utils/PriceUtil';

function Works(props: { row: Row; language: string; index: number; handleImage: (arg0: string, arg1: boolean) => void; showBuyForm: (arg0: string) => void; }) {
  const { t } = useTranslation();  
  const getText = (row: Row) => {
    if (props.language === 'fr') return row.textFr;
    if (props.language === 'pt') return row.textPt;
    else return row.textEn;
  }
  let button;
  if (props.row.available) {
    button = <button className='buyBtnPrinc' onClick={() => props.showBuyForm(props.row.image)}>{t('Buy')}</button>            
  } else {
    button = <button className='buyBtnSoldOut' disabled>{t('SoldOut')}</button>
  }
  const loading = props.index > 4 ? "lazy" : "eager";
  const getPrices = () => {
    if (props.row.available) {
      let priceList = "";
      props.row.sizes?.forEach(s => {
        priceList += s.description + " : " + getPrice(s) + "<br>";
      });
      return priceList;
    } else {
      return "";
    }    
  }
  return (
      <div className={'container filterDiv ' + props.row.serie}>
          <img src={defaultUrl + props.row.image + endUrl} className="image" alt='' loading={loading} ></img>
          <div className="middle" onClick={(e) => props.handleImage(props.row.image, props.row.available)}>
            <text dangerouslySetInnerHTML={{ __html: getText(props.row) + "<br><br>" + getPrices()}}></text>
          </div>
          {button}
      </div>
  )
}

export default Works;