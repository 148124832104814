import React from 'react';
import { AlertColor, Box, Button, createTheme, FormControl, MenuItem, TextField, ThemeProvider } from '@mui/material';
import { useTranslation } from "react-i18next";
import { send } from '../services/EmailService';
import data from '../data/data2.json';
import { getPrice } from '../utils/PriceUtil';
import { Size } from '../types/Types';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function BuyForm(props: { image: string; handleClose: () => void; setAlertProperties: (arg0: boolean, arg1: string, arg2: AlertColor) => void; }) {
    const { t } = useTranslation();
    const [size, setSize] = React.useState("");
    const [firstname, setFirstname] = React.useState("");
    const [lastname, setLastname] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [city, setCity] = React.useState("");
    const [zipcode, setZipcode] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [isEmailValid, setIsEmailValid] = React.useState(true);
    const regex = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
    const validateEmail = () => setIsEmailValid(email.length === 0 || regex.test(email));
    const isEnabled = size.length > 0 && firstname.length > 0 && lastname.length > 0 && isEmailValid && phone.length > 0 && address.length > 0 && city.length > 0 && zipcode.length > 0;
    const close = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        props.handleClose()
      }
    const sendBtn = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        var r = await send({ image: props.image, size, firstname, lastname, email, phone, address, city, zipcode, message });
        close(e);
        if (r) {
            props.setAlertProperties(true, t('EmailSendSuccess'), 'success');
        } else {
            props.setAlertProperties(true, t('EmailSendError'), 'error');
        }
    };
    const getSize = (size: Size) => {
        let price = getPrice(size);
        return size.description + " (" + price + " + " + t('Shipping') + ")";
    }
    return (
        <ThemeProvider theme={darkTheme}>
            <Box sx={{ maxWidth: '800px', textAlign: 'center', padding: '20px' }}>
                <a href="/#" className="closebtnimage" onClick={(e) => close(e)}>&times;</a>
                <img
                      src={'./images/thumb/' + props.image}
                      alt=""
                      style={{maxWidth: '30%', width: 'auto', height: 'auto', maxHeight: '20%', outline: "none" }}
                    />
                <FormControl fullWidth>
                    <TextField
                        select
                        id="size"
                        label={t('Size')}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSize(e.target.value)}
                        variant="standard"
                        required
                    >
                        {
                            data.find(r => r.available && r.image === props.image)?.sizes?.map(s => {
                                return (<MenuItem value={getSize(s)}>{getSize(s)}</MenuItem>)
                            })
                        }
                    </TextField>
                    <TextField fullWidth required id="firstname" label={t('Firstname')} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstname(e.target.value)} variant="standard"/>
                    <TextField fullWidth required id="lastname" label={t('Lastname')} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastname(e.target.value)} variant="standard"/>
                    <TextField type={'email'} fullWidth required id="email" label={t('Email')} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} variant="standard" onBlur={()=>validateEmail()} error={!isEmailValid} helperText={isEmailValid ? '' : 'Please enter a valid Email'}/>
                    <TextField fullWidth required id="phone" label={t('Phone')} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)} variant="standard"/>
                    <TextField fullWidth required id="address" label={t('Address')} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAddress(e.target.value)} variant="standard"/>
                    <TextField fullWidth required id="city" label={t('City')} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCity(e.target.value)} variant="standard"/>
                    <TextField fullWidth required id="zipcode" label={t('Zipcode')} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setZipcode(e.target.value)} variant="standard"/>
                    <TextField fullWidth multiline rows={4} id="message" label={t('Message')} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)} variant="standard"/>
                    <br></br>
                    <Button variant="outlined" disabled={!isEnabled} onClick={(e) => sendBtn(e)}>{t('Send')}</Button>
                </FormControl>
            </Box>
        </ThemeProvider>
    )
}

export default BuyForm;