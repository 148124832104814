import emailjs from '@emailjs/browser';

type TemplateParams = {
    image: string;
    size: string;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    address: string;
    city: string;
    zipcode: string;
    message: string;
  }

export async function send(templateParams: TemplateParams) : Promise<boolean> {
    return emailjs.send('service_0cayfrk','template_jeb1c7a', templateParams, 'Ir1imF_eeIAY-ahuL')
	.then((response) => {
	   console.log('SUCCESS!', response.status, response.text);
       return true;
	}, (err) => {
	   console.log('FAILED...', err);
       return false;
	});
}
