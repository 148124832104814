import React, { useEffect, useState } from 'react';
import './App.css';
import { Alert, AlertColor, Snackbar } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { logEvent } from 'firebase/analytics';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import i18n from "./services/I18nService";
import { useTranslation } from "react-i18next";
import Works from './components/Works';
import About from './components/About';
import { filterSelection } from './utils/FilterUtil';
import { TabPanel, a11yProps} from './components/TabPanel';
import Filter from './components/Filter';
import ModalContainer from './components/ModalContainer';
import Language from './components/Language';
import { Column, Row } from './types/Types';
import { analytics, database } from './services/FirebaseService';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';

function App() {
  const { t } = useTranslation();
  var l = 'en';
  const l2 = i18n.language.split("-")[0];
  if (['en', 'fr', 'pt'].includes(l2)) l = l2;

  const [language, setLanguage] = React.useState(l);
  const handleLanguage = (value: string) => {
    setLanguage(value);
    i18n.changeLanguage(value);
  };
  const [openModal, setOpenModal] = React.useState(false);
  const [openBuyForm, setOpenBuyForm] = React.useState(false);  
  const handleClose = () => setOpenModal(false);
  const [image, setImage] = React.useState("false");
  const [available, setAvailable] = React.useState(false);
  const handleImage = (value: string, valueAvailable: boolean) => {
    setImage(value);
    setOpenBuyForm(false);
    setOpenModal(true);
    setAvailable(valueAvailable);
    logEvent(analytics, value);
    window.history.pushState(null, '', window.location.href);
  };
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const openInNewTab = (url: string | URL | undefined) => {
    window.open(url);
  };
  window.onpopstate = (event) => {
    setOpenModal(false);
  }
  logEvent(analytics, "OK");
  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertColor>('success');
  const setAlertProperties = (alert1: boolean, alertContent1: string, alertSeverity1: AlertColor) => {
    setAlert(alert1);
    setAlertContent(alertContent1);
    setAlertSeverity(alertSeverity1)
  }
  const handleCloseSnackbar = () => setAlert(false);
  const showBuyForm = (value: string) => {
    setImage(value);
    setOpenBuyForm(true);
    setOpenModal(true);
  }
  const [data, setData] = useState<Row[]>([]);
  useEffect(() => {
    const artsRef = collection(database, "arts");
    const q = query(artsRef, orderBy("row.id", "asc"));
    onSnapshot(q, (snapshot) => {
      setData([]);
      snapshot.docs.forEach((doc) => {
        setData((prev) => [...prev, doc.data().row]);
      });
    });
  }, []);

  let a: Column[] = [{rows:[] },{rows:[] },{rows:[] },{rows:[] },];
  data.slice(0).reverse().forEach((row, index) => {
    a[index%4].rows.push(row);
  });
  return (
    <div className='App'>
      <div>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={alert} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={alertSeverity} sx={{ width: '100%' }}>
            {alertContent}
          </Alert>
        </Snackbar>
        <div className='header'>
          <h1>Leonardo Lima</h1>
        </div>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleTabChange} centered TabIndicatorProps={{style: {background:'#FFFFFF'}}}>
              <Tab style={{color: "#FFFFFF", fontFamily: 'Josefin Sans', fontSize: '1em'}} label={t("Works")} {...a11yProps(0)} />
              <button className="buttonTab" type="button" onClick={() => openInNewTab('https://www.instagram.com/leonardo_lima.art')}>{t('Insta')}</button>
              <Tab style={{color: "#FFFFFF", fontFamily: 'Josefin Sans', fontSize: '1em'}} label={t("About")} {...a11yProps(2)} onClick={() =>filterSelection("all")}/>
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <div className='filterTextDiv faunaflora hidden'>
              <text dangerouslySetInnerHTML={{ __html: t("Text-serie-faunaflora")}}></text>
            </div>
            <div className='filterTextDiv abracos hidden'>
              <text dangerouslySetInnerHTML={{ __html: t("Text-serie-abracos")}}></text>
            </div>
            <div className='filterTextDiv olhosVegetais hidden'>
              <text dangerouslySetInnerHTML={{ __html: t("Text-serie-olhosVegetais")}}></text>
            </div>
            <div className='filterTextDiv mangal hidden'>
              <text dangerouslySetInnerHTML={{ __html: t("Text-serie-mangal")}}></text>
            </div>

            <div className='row'>
              {a.map(column => {
                return (<div className='column'>{
                  column.rows.map((row, index) => {
                    return (<Works row={row} index={index} language={language} handleImage={handleImage} showBuyForm={showBuyForm}></Works>)
                  })}</div>)
              })}
            </div>
            <Filter></Filter>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <About></About>
          </TabPanel>
        </Box>
        

        <div className='footer'>
          <p>{t("Copyright")}</p>
        </div>

        <ModalContainer openModal={openModal} openBuyForm={openBuyForm} handleClose={handleClose} showBuyForm={showBuyForm} image={image} setAlertProperties={setAlertProperties} available={available}></ModalContainer>
      </div>

      <Language language={language} handleLanguage={handleLanguage}></Language>
    </div>
  );
}

export default App;
