import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_EN } from "../locale/en";
import { TRANSLATIONS_FR } from "../locale/fr";
import { TRANSLATIONS_PT } from "../locale/pt";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      fr: {
        translation: TRANSLATIONS_FR,
      },
      pt: {
        translation: TRANSLATIONS_PT,
      },
    },
  });

export default i18n;